<template>
  <div class="primary-chart__wrap">
    <canvas id="primaryChart" ref="canvas" :width="chartWidth" :height="chartHeight"></canvas>
  </div>
</template>

<style lang="scss" scoped>
.primary-chart__wrap {
  margin: 1rem;
}
</style>

<script>
import Chart from "chart.js";
import TickerHistoryService from "../services/TickerHistoryService";

const tickerHistoryService = new TickerHistoryService();

export default {
  props: {
    sizeRatio: { type: String },
    tickerSymbol: { type: String },
    lineTension: { type: Number, default: 0.0, required: false }
  },
  computed: {
    chartWidth: function() {
      return this.chartHeight * this.sizeRatio;
    }
  },
  data: function() {
    return {
      chartHeight: 100,
      ctx: {}, // the chart context
      chart: {}, // the chart instance
      chartData: {
        type: "line",
        data: {},
        options: {
          animation: false,
          scales: {
            xAxes: [
              {
                ticks: {}
              }
            ],
            yAxes: [
              {
                gridLines: {
                  display: false
                },
                scaleLabel: {
                  display: true,
                  labelString: "$ Value"
                },
                ticks: {}
              }
            ]
          }
        }
      }
    };
  },
  watch: {
    tickerSymbol: function() {
      // console.log(
      //   "PrimaryChart.vue",
      //   "Watched tickerSymbol changed to " + this.tickerSymbol
      // );
      this.buildTickerData(this.tickerSymbol);
    }
  },
  mounted: function() {
    this.ctx = this.$refs.canvas.getContext("2d");
    console.log("PrimaryChart.vue", "Creating empty chart property");
    this.chart = new Chart(this.ctx, null);
  },
  methods: {
    loadChart: function(data) {
      console.log("PrimaryChart.vue", "Destroying previous chart");
      this.chart.destroy();
      if (data !== null) {
        this.chartData.data = data;
      }
      console.log("PrimaryChart.vue", "Creating chart with data");
      this.chart = new Chart(this.ctx, this.chartData);
    },
    buildTickerData: function(symbol) {
      let lineTension = parseFloat(this.lineTension);
      let fillOpacity = 0.0;
      let tickerData = tickerHistoryService.getAllDataForTicker(symbol);

      let tickerWeeks = tickerData.map(i => new Date(i.date).toLocaleDateString()).unique();

      let history = {
        labels: tickerWeeks,
        datasets: [
          {
            label: "Price",
            data: tickerData.map(i => i.price),
            fill: true,
            borderColor: "rgba(0, 255, 0, 1)",
            borderWidth: 5,
            backgroundColor: "rgba(0, 255, 0, " + fillOpacity + ")",
            lineTension: lineTension
          },
          {
            label: "Starting Low Target Price Estimate",
            data: tickerData
              .map(i => i["low-target-price-estimate"])
              .firstAsLast(),
            fill: true,
            borderColor: "rgba(100, 100, 100, 1)",
            borderWidth: 3,
            backgroundColor: "rgba(100, 100, 100, " + fillOpacity + ")",
            lineTension: 0,
            spanGaps: true
          },
          {
            label: "Starting High Target Price Estimate",
            data: tickerData
              .map(i => i["high-target-price-estimate"])
              .firstAsLast(),
            fill: true,
            borderColor: "rgba(100, 100, 100, 1)",
            borderWidth: 3,
            backgroundColor: "rgba(100, 100, 100, " + fillOpacity + ")",
            lineTension: 0,
            spanGaps: true
          },
          {
            label: "Starting Mean Target Price Estimate",
            data: tickerData
              .map(i => i["mean-con-target-price"])
              .firstAsLast(),
            fill: true,
            borderColor: "rgba(0, 70, 255, 1)",
            borderWidth: 2,
            backgroundColor: "rgba(0, 70, 255, " + fillOpacity + ")",
            lineTension: 0,
            spanGaps: true
          },
          {
            label: "Low Target Price Estimate",
            data: tickerData.map(i => i["low-target-price-estimate"]),
            fill: true,
            borderColor: "rgba(180, 180, 180, 1)",
            borderWidth: 1,
            backgroundColor: "rgba(180, 180, 180, " + fillOpacity + ")",
            lineTension: lineTension,
            spanGaps: true
          },
          {
            label: "High Target Price Estimate",
            data: tickerData.map(i => i["high-target-price-estimate"]),
            fill: true,
            borderColor: "rgba(180, 180, 180, 1)",
            borderWidth: 1,
            backgroundColor: "rgba(180, 180, 180, " + fillOpacity + ")",
            lineTension: lineTension,
            spanGaps: true
          },
          {
            label: "Mean Target Price Estimate",
            data: tickerData.map(i => i["mean-con.-target-price"]),
            fill: true,
            borderColor: "rgba(0, 200, 255, 1)",
            borderWidth: 1,
            backgroundColor: "rgba(0, 200, 255, " + fillOpacity + ")",
            lineTension: lineTension,
            spanGaps: true
          }
        ]
      };

      // Determine min and max value
      let minValue = tickerData[0].price;
      let maxValue = tickerData[0].price;
      tickerData.forEach(i => {
        // console.log(i);
        minValue = Math.min(
          minValue,
          i.price,
          i['low-target-price-estimate'],
          i['high-target-price-estimate'],
          i['mean-con-target-price']
        );
        maxValue = Math.max(
          maxValue,
          i.price,
          i['low-target-price-estimate'],
          i['high-target-price-estimate'],
          i['mean-con-target-price']
        );
      });
      // console.log(maxValue, maxValue * 1.1, minValue, minValue * 0.9);
      this.chartData.options.scales.yAxes[0].ticks.max = Math.ceil(
        maxValue * 1.05
      );
      this.chartData.options.scales.yAxes[0].ticks.min = Math.floor(
        minValue * 0.95
      );

      this.loadChart(history);
    }
  }
};
</script>
