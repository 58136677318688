var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ticker-list" }, [
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.query,
          expression: "query"
        }
      ],
      staticClass: "ticker-list__search",
      attrs: { type: "search", autofocus: "" },
      domProps: { value: _vm.query },
      on: {
        input: function($event) {
          if ($event.target.composing) {
            return
          }
          _vm.query = $event.target.value
        }
      }
    }),
    _vm._v(" "),
    _c(
      "select",
      {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.selectedSymbol,
            expression: "selectedSymbol"
          }
        ],
        attrs: { size: "10" },
        on: {
          change: [
            function($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function(o) {
                  return o.selected
                })
                .map(function(o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.selectedSymbol = $event.target.multiple
                ? $$selectedVal
                : $$selectedVal[0]
            },
            _vm.onChange
          ]
        }
      },
      _vm._l(_vm.symbols, function(symbol) {
        return _c("option", { key: symbol }, [
          _vm._v("\n      " + _vm._s(symbol) + "\n    ")
        ])
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }