import RestService from './RestService';
import countryData from './ISO3166-CountryList.json';

const restService = new RestService();

export default class CountryService {
  constructor() {
    this.countryData = countryData;
    this.flagUrls = {};
  }

  getFlagImage(countryName) {
    let found = this.countryData.filter(i => i.EnglishName === countryName);

    // If nothing found, bail.
    if (found.length <= 0) {
      return Promise.resolve(null);
    }

    let needle = found[0].Alpha3;
    console.log('CountryService: needle = ', needle);

    // Check for a cached image
    let flag = this.flagUrls[needle];
    if (flag) {
      console.info('Returning cached image', flag);
      return Promise.resolve(flag);
    }

    let baseUrl = 'https://restcountries.eu/rest/v2';
    let actionUrl = 'alpha';
    let requestUrl = `${baseUrl}/${actionUrl}/${needle}`;
    console.info('The request URL is', requestUrl);

    // store reference to `this.flagUrls`
    let cache = this.flagUrls;

    return new Promise(function (resolve, reject) {
      restService
        .sendRequest('GET', requestUrl)
        .then(resp => {
          cache[needle] = resp.flag;
          resolve(resp.flag);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}