var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("h2", { class: { "ticker--warning": _vm.numAnalysts == 0 } }, [
    _c("div", { staticClass: "ticker__symbol" }, [
      _vm._v(_vm._s(_vm.tickerSymbol))
    ]),
    _vm._v(" "),
    _c("img", {
      staticClass: "ticker__country-flag",
      attrs: { src: _vm.flagUrl, alt: _vm.country, title: _vm.country }
    }),
    _vm._v(" "),
    _c("div", { staticClass: "ticker__company" }, [
      _vm._v(_vm._s(_vm.company))
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "ticker__grades" }, [
      _c("span", { attrs: { title: "Latest Profitability Grade" } }, [
        _vm._v(_vm._s(_vm.profitabilityGrade))
      ]),
      _vm._v(" "),
      _c("span", { attrs: { title: "Latest Financial Health Grade" } }, [
        _vm._v(_vm._s(_vm.financialHealthGrade))
      ]),
      _vm._v(" "),
      _c("span", { attrs: { title: "Latest Growth Grade" } }, [
        _vm._v(_vm._s(_vm.growthGrade))
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "ticker__exchange" }, [
      _vm._v(_vm._s(_vm.exchange))
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "ticker__numAnalysts",
        class: { "ticker__numAnalysts--warning": _vm.numAnalysts == 0 }
      },
      [_vm._v("Analysts: " + _vm._s(_vm.numAnalysts))]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }