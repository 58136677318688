export default class RestService {
  constructor() {}

  sendRequest(method, url) {
    return this._fetchJSON(method, url);
  }

  _fetchJSON(method, url) {
    return new Promise(function (resolve, reject) {
      // Guard
      if(!method) {
        method = 'GET';
      }
      if(!url) {
        reject({
          status: '404',
          statusText: 'The URL was empty.'
        });
      }

      var xhr = new XMLHttpRequest();
      xhr.open(method, url);
      xhr.onload = function () {
        if (this.status >= 200 && this.status < 300) {
          let json = JSON.parse(xhr.response);
          resolve(json);
        } else {
          reject({
            status: this.status,
            statusText: xhr.statusText
          });
        }
      };
      xhr.onerror = function () {
        reject({
          status: this.status,
          statusText: xhr.statusText
        });
      };
      xhr.send();
    });
  }
}