<template>
  <div class="rating-chart">
    <chart-js-component 
    ref="chart" 
    size-ratio="8" 
    chart-type="line" 
    :datasets="datasets" 
    :labels="labels" 
    :options="options"
    />
  </div>
</template>

<style lang="scss" scoped>
.rating-chart {
  padding: 1rem;
  background-color: white;
}
</style>

<script>
import ChartJsComponent from "./ChartJsComponent.vue";

const MaxConsensusRating = 5;

export default {
  components: {
    ChartJsComponent
  },
  props: {
    tickerSymbol: { type: String, required: true },
    tickerHistoryService: { type: Object }
  },
  data: function() {
    return {
      options: {
        animation: false,
        scales: {
          yAxes: [
            {
              ticks: {
                min: 0,
                max: 5,
                reverse: false
              },
              gridLines: {
                display: false
              },
              scaleLabel: {
                display: true,
                labelString: "One to Five"
              },
              gridLines: {
                drawOnChartArea: false // only want the grid lines for one axis to show up
              }
            }
          ]
        }
      },
      labels: [],
      datasets: [
        {
          label: "Consensus Rating",
          data: [],
          fill: true,
          borderColor: "hsla(50, 100%, 40%, 1)",
          borderWidth: 2,
          backgroundColor: "hsla(50, 100%, 50%, 0)",
          lineTension: 0.33,
          steppedLine: true,
          spandGaps: true,
          pointRadius: 2
        }
      ]
    };
  },
  mounted: function() {
    this.$refs.chart.loadChart(this.labels, this.datasets);
  },
  watch: {
    tickerSymbol: function() {
      console.log(
        "RatingChart.vue",
        "Watched tickerSymbol changed to " + this.tickerSymbol
      );
      let consensusData = this.tickerHistoryService.getConsensusRatingData(
        this.tickerSymbol
      );
      console.log(consensusData);
      this.labels = consensusData.map(l => l.label);
      this.datasets[0].data = consensusData.map(
        d => MaxConsensusRating - d.rating
      );

      this.$refs.chart.loadChart(this.labels, this.datasets);
    }
  }
};
</script>
