import Vue from 'vue';
import App from './App.vue';

new Vue({
  el: '#brigApp',
  render: h => h(App)
});

/*
import "./extensions/arrayExtensions";
import "./styles/theme.scss";

import LoadingSplash from "./components/loading-splash/loading-splash";
import TickerService from "./components/ticker-service/ticker-service";
import TickerList from "./components/ticker-list/ticker-list";
import DemoChart from "./components/demo-chart/demo-chart";

import data2018074 from "./history/Consider-2018-07-Week4.json";
import data2018081 from "./history/Consider-2018-08-Week1.json";
import data2018082 from "./history/Consider-2018-08-Week2.json";

(function(layoutRootId, appRootId) {
  let layoutRoot = document.getElementById(layoutRootId);
  let templateRoot = document.getElementById(appRootId);

  var loadingSplash = new LoadingSplash();
  layoutRoot.appendChild(loadingSplash.render());
  loadingSplash.hide();

  let tickerService = new TickerService();
  tickerService.loadData(data2018074);
  tickerService.loadData(data2018081);
  tickerService.loadData(data2018082);

  // Add the DemoChart to the UI
  let demoChart = new DemoChart(2);
  let $demoChart = demoChart.render();
  $demoChart.classList.add("price-chart");
  templateRoot.appendChild($demoChart);

  // Add ticker list to the UI
  let tickerList = new TickerList(tickerService.tickerSymbols, symbol => {
    console.log("ticker was changed to", symbol);
    buildChartForSymbol(symbol);
  });
  templateRoot.appendChild(tickerList.render());

  function buildChartForSymbol(symbol) {
    let lineTension = 0.25;
    let fillOpacity = 0.25;
    // Convert the data into chart datasets
    let tickerData = tickerService.getAllDataForTicker(symbol);
    let history = {
      labels: tickerService.weeks,
      datasets: [
        {
          label: "Price",
          data: tickerData.map(i => i.Price),
          fill: true,
          borderColor: "rgba(0, 255, 0, 1)",
          backgroundColor: "rgba(0, 255, 0, 0.33)",
          lineTension: lineTension
        },
        // {
        //   label: "Starting Mean Target Price",
        //   data: tickerData
        //     .map(i => i["Mean Con. Target Price"])
        //     .firstAsLast(),
        //   fill: true,
        //   borderColor: "rgba(100, 100, 100, 1)",
        //   borderWidth: 1,
        //   backgroundColor: "rgba(100, 100, 100, "+fillOpacity+")",
        //   lineTension: 0,
        //   spanGaps: true
        // },
        {
          label: "Starting Low Target Price Estimate",
          data: tickerData
            .map(i => i["Low Target Price Estimate"])
            .firstAsLast(),
          fill: true,
          borderColor: "rgba(100, 100, 100, 1)",
          borderWidth: 1,
          backgroundColor: "rgba(100, 100, 100, " + fillOpacity + ")",
          lineTension: 0,
          spanGaps: true
        },
        {
          label: "Starting High Target Price Estimate",
          data: tickerData
            .map(i => i["High Target Price Estimate"])
            .firstAsLast(),
          fill: true,
          borderColor: "rgba(100, 100, 100, 1)",
          borderWidth: 1,
          backgroundColor: "rgba(100, 100, 100, " + fillOpacity + ")",
          lineTension: 0,
          spanGaps: true
        },
        // {
        //   label: "Mean Target Price",
        //   data: tickerData.map(i => i["Mean Con. Target Price"]),
        //   fill: true,
        //   borderColor: "rgba(180, 180, 180, 1)",
        //   borderWidth: 1,
        //   backgroundColor: "rgba(180, 180, 180, "+fillOpacity+")",
        //   lineTension: lineTension,
        //   // steppedLine: true,
        //   spanGaps: true
        // },
        {
          label: "Low Target Price Estimate",
          data: tickerData.map(i => i["Low Target Price Estimate"]),
          fill: true,
          borderColor: "rgba(180, 180, 180, 1)",
          borderWidth: 1,
          backgroundColor: "rgba(180, 180, 180, " + fillOpacity + ")",
          lineTension: lineTension,
          // steppedLine: true,
          spanGaps: true
        },
        {
          label: "High Target Price Estimate",
          data: tickerData.map(i => i["High Target Price Estimate"]),
          fill: true,
          borderColor: "rgba(180, 180, 180, 1)",
          borderWidth: 1,
          backgroundColor: "rgba(180, 180, 180, " + fillOpacity + ")",
          lineTension: lineTension,
          // steppedLine: true,
          spanGaps: true
        }
      ]
    };
    // Load data into the DemoChart
    demoChart.loadChart(history);
  }
})("layoutRoot", "brigApp");
*/