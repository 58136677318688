import '../extensions/arrayExtensions';
import data2018074 from '../history/Consider-2018-07-Week4.json';
import data2018081 from '../history/Consider-2018-08-Week1.json';
import data2018082 from '../history/Consider-2018-08-Week2.json';
import data2018083 from '../history/Consider-2018-08-Week3.json';
import data2018084 from '../history/Consider-2018-08-Week4.json';
import data2018085 from '../history/Consider-2018-08-Week5.json';
import data2018091 from '../history/Consider-2018-09-Week1.json';
import data2018092 from '../history/Consider-2018-09-Week2.json';
import data2018093 from '../history/Consider-2018-09-Week3.json';
import data2018094 from '../history/Consider-2018-09-Week4.json';
import data2018101 from '../history/Consider-2018-10-Week1.json';
import data2018102 from '../history/Consider-2018-10-Week2.json';
import data2018103 from '../history/Consider-2018-10-Week3.json';
import data2018104 from '../history/Consider-2018-10-Week4.json';
import data2018105 from '../history/Consider-2018-10-Week5.json';
import data2018111 from '../history/Consider-2018-11-Week1.json';
import data2018112 from '../history/Consider-2018-11-Week2.json';
import data2018113 from '../history/Consider-2018-11-Week3.json';
import data2018114 from '../history/Consider-2018-11-Week4.json';
import data2018121 from '../history/Consider-2018-12-Week1.json';
import data2018122 from '../history/Consider-2018-12-Week2.json';
import data2018123 from '../history/Consider-2018-12-Week3.json';
import data2018124 from '../history/Consider-2018-12-Week4.json';
import data2019011 from '../history/Consider-2019-01-Week1.json';
import data2019012 from '../history/Consider-2019-01-Week2.json';
import data2019013 from '../history/Consider-2019-01-Week3.json';
import data2019014 from '../history/Consider-2019-01-Week4.json';
import data2019021 from '../history/Consider-2019-02-Week1.json';
import data2019022 from '../history/Consider-2019-02-Week2.json';
import data2019023 from '../history/Consider-2019-02-Week3.json';
import data2019024 from '../history/Consider-2019-02-Week4.json';
import data2019031 from '../history/Consider-2019-03-Week1.json';
import data2019033 from '../history/Consider-2019-03-Week3.json';
import data2019034 from '../history/Consider-2019-03-Week4.json';
import data2019041 from '../history/Consider-2019-04-Week1.json';
import data2019042 from '../history/Consider-2019-04-Week2.json';
import data2019043 from '../history/Consider-2019-04-Week3.json';
import data2019044 from '../history/Consider-2019-04-Week4.json';
import data2019051 from '../history/Consider-2019-05-Week1.json';
import data2019052 from '../history/Consider-2019-05-Week2.json';
import data2019053 from '../history/Consider-2019-05-Week3.json';
import data2019054 from '../history/Consider-2019-05-Week4.json';
import data2019055 from '../history/Consider-2019-05-Week5.json';
import data2019061 from '../history/Consider-2019-06-Week1.json';
import data2019062 from '../history/Consider-2019-06-Week2.json';
import data2019063 from '../history/Consider-2019-06-Week3.json';
import data2019064 from '../history/Consider-2019-06-Week4.json';
import data2019071 from '../history/Consider-2019-07-Week1.json';
import data2019073 from '../history/Consider-2019-07-Week3.json';
import data2019074 from '../history/Consider-2019-07-Week4.json';
import data2019075 from '../history/Consider-2019-07-Week5.json';
import data2019081 from '../history/Consider-2019-08-Week1.json';
import data2019082 from '../history/Consider-2019-08-Week2.json';
import data2019083 from '../history/Consider-2019-08-Week3.json';
import data2019091 from '../history/Consider-2019-09-Week1.json';
import data2019092 from '../history/Consider-2019-09-Week2.json';
import data2019093 from '../history/Consider-2019-09-Week3.json';
import data2019094 from '../history/Consider-2019-09-Week4.json';
import data2019101 from '../history/Consider-2019-10-Week1.json';
import data2019102 from '../history/Consider-2019-10-Week2.json';
import data2019103 from '../history/Consider-2019-10-Week3.json';
import data2019104 from '../history/Consider-2019-10-Week4.json';
import data2019105 from '../history/Consider-2019-10-Week5.json';
import data2019111 from '../history/Consider-2019-11-Week1.json';
import data2019112 from '../history/Consider-2019-11-Week2.json';
import data2019113 from '../history/Consider-2019-11-Week3.json';
import data2019114 from '../history/Consider-2019-11-Week4.json';
import data2019121 from '../history/Consider-2019-12-Week1.json';
import data2019122 from '../history/Consider-2019-12-Week2.json';
import data2019123 from '../history/Consider-2019-12-Week3.json';
import data2019124 from '../history/Consider-2019-12-Week4.json';
import data2020011 from '../history/Consider-2020-01-Week1.json';
import data2020012 from '../history/Consider-2020-01-Week2.json';
import data2020013 from '../history/Consider-2020-01-Week3.json';
import data2020014 from '../history/Consider-2020-01-Week4.json';
import data2020015 from '../history/Consider-2020-01-Week5.json';
import data2020021 from '../history/Consider-2020-02-Week1.json';
import data2020022 from '../history/Consider-2020-02-Week2.json';
import data2020023 from '../history/Consider-2020-02-Week3.json';
import data2020024 from '../history/Consider-2020-02-Week4.json';
import data2020031 from '../history/Consider-2020-03-Week1.json';
import data2020032 from '../history/Consider-2020-03-Week2.json';
import data2020033 from '../history/Consider-2020-03-Week3.json';
import data2020034 from '../history/Consider-2020-03-Week4.json';
import data2020041 from '../history/Consider-2020-04-Week1.json';
import data2020042 from '../history/Consider-2020-04-Week2.json';
import data2020043 from '../history/Consider-2020-04-Week3.json';
import data2020044 from '../history/Consider-2020-04-Week4.json';
import data2020045 from '../history/Consider-2020-04-Week5.json';
import data2020051 from '../history/Consider-2020-05-Week1.json';
import data2020052 from '../history/Consider-2020-05-Week2.json';
import data2020053 from '../history/Consider-2020-05-Week3.json';
import data2020054 from '../history/Consider-2020-05-Week4.json';
import data2020062 from '../history/Consider-2020-06-Week2.json';
import data2020063 from '../history/Consider-2020-06-Week3.json';
import data2020064 from '../history/Consider-2020-06-Week4.json';
import data2020071 from '../history/Consider-2020-07-Week1.json';
import data2020072 from '../history/Consider-2020-07-Week2.json';
import data2020073 from '../history/Consider-2020-07-Week3.json';
import data2020075 from '../history/Consider-2020-07-Week5.json';
import data2020081 from '../history/Consider-2020-08-Week1.json';
import data2020082 from '../history/Consider-2020-08-Week2.json';
import data2020083 from '../history/Consider-2020-08-Week3.json';
import data2020084 from '../history/Consider-2020-08-Week4.json';
import data2020091 from '../history/Consider-2020-09-Week1.json';
import data2020092 from '../history/Consider-2020-09-Week2.json';
import data2020093 from '../history/Consider-2020-09-Week3.json';
import data2020094 from '../history/Consider-2020-09-Week4.json';
import data2020095 from '../history/Consider-2020-09-Week5.json';
import data2020101 from '../history/Consider-2020-10-Week1.json';
import data2020102 from '../history/Consider-2020-10-Week2.json';
import data2020103 from '../history/Consider-2020-10-Week3.json';
import data2020104 from '../history/Consider-2020-10-Week4.json';
import data2020111 from '../history/Consider-2020-11-Week1.json';
import data2020112 from '../history/Consider-2020-11-Week2.json';
import data2020113 from '../history/Consider-2020-11-Week3.json';
import data2020114 from '../history/Consider-2020-11-Week4.json';
import data2020121 from '../history/Consider-2020-12-Week1.json';
import data2020122 from '../history/Consider-2020-12-Week2.json';
import data2020123 from '../history/Consider-2020-12-Week3.json';
import data2020124 from '../history/Consider-2020-12-Week4.json';
import data2020125 from '../history/Consider-2020-12-Week5.json';
import data2021011 from '../history/Consider-2021-01-Week1.json';
import data2021012 from '../history/Consider-2021-01-Week2.json';
import data2021013 from '../history/Consider-2021-01-Week3.json';
import data2021014 from '../history/Consider-2021-01-Week4.json';
import data2021021 from '../history/Consider-2021-02-Week1.json';
import data2021022 from '../history/Consider-2021-02-Week2.json';
import data2021023 from '../history/Consider-2021-02-Week3.json';
import data2021024 from '../history/Consider-2021-02-Week4.json';
import data2021031 from '../history/Consider-2021-03-Week1.json';
import data2021033 from '../history/Consider-2021-03-Week3.json';

const keyIndexSymbol = 0;
const keyIndexDate = 1;

export default class TickerHistoryService {
  constructor() {
    this.selectedSymbol = '';
    this.historyData = [];
    this.tickerSymbols = [];
    this.weeks = [];

    // Load each week's data
    this.loadData(data2018074);
    this.loadData(data2018081);
    this.loadData(data2018082);
    this.loadData(data2018083);
    this.loadData(data2018084);
    this.loadData(data2018085);
    this.loadData(data2018091);
    this.loadData(data2018092);
    this.loadData(data2018093);
    this.loadData(data2018094);
    this.loadData(data2018101);
    this.loadData(data2018102);
    this.loadData(data2018103);
    this.loadData(data2018104);
    this.loadData(data2018105);
    this.loadData(data2018111);
    this.loadData(data2018112);
    this.loadData(data2018113);
    this.loadData(data2018114);
    this.loadData(data2018121);
    this.loadData(data2018122);
    this.loadData(data2018123);
    this.loadData(data2018124);
    this.loadData(data2019011);
    this.loadData(data2019012);
    this.loadData(data2019013);
    this.loadData(data2019014);
    this.loadData(data2019021);
    this.loadData(data2019022);
    this.loadData(data2019023);
    this.loadData(data2019024);
    this.loadData(data2019031);
    this.loadData(data2019033);
    this.loadData(data2019034);
    this.loadData(data2019041);
    this.loadData(data2019042);
    this.loadData(data2019043);
    this.loadData(data2019044);
    this.loadData(data2019051);
    this.loadData(data2019052);
    this.loadData(data2019053);
    this.loadData(data2019054);
    this.loadData(data2019055);
    this.loadData(data2019061);
    this.loadData(data2019062);
    this.loadData(data2019063);
    this.loadData(data2019064);
    this.loadData(data2019071);
    this.loadData(data2019073);
    this.loadData(data2019074);
    this.loadData(data2019075);
    this.loadData(data2019081);
    this.loadData(data2019082);
    this.loadData(data2019083);
    this.loadData(data2019091);
    this.loadData(data2019092);
    this.loadData(data2019093);
    this.loadData(data2019094);
    this.loadData(data2019101);
    this.loadData(data2019102);
    this.loadData(data2019103);
    this.loadData(data2019104);
    this.loadData(data2019105);
    this.loadData(data2019111);
    this.loadData(data2019112);
    this.loadData(data2019113);
    this.loadData(data2019114);
    this.loadData(data2019121);
    this.loadData(data2019122);
    this.loadData(data2019123);
    this.loadData(data2019124);
    this.loadData(data2020011);
    this.loadData(data2020012);
    this.loadData(data2020013);
    this.loadData(data2020014);
    this.loadData(data2020015);
    this.loadData(data2020021);
    this.loadData(data2020022);
    this.loadData(data2020023);
    this.loadData(data2020024);
    this.loadData(data2020031);
    this.loadData(data2020032);
    this.loadData(data2020033);
    this.loadData(data2020034);
    this.loadData(data2020041);
    this.loadData(data2020042);
    this.loadData(data2020043);
    this.loadData(data2020044);
    this.loadData(data2020045);
    this.loadData(data2020051);
    this.loadData(data2020052);
    this.loadData(data2020053);
    this.loadData(data2020054);
    this.loadData(data2020062);
    this.loadData(data2020063);
    this.loadData(data2020064);
    this.loadData(data2020071);
    this.loadData(data2020072);
    this.loadData(data2020073);
    this.loadData(data2020075);
    this.loadData(data2020081);
    this.loadData(data2020082);
    this.loadData(data2020083);
    this.loadData(data2020084);
    this.loadData(data2020091);
    this.loadData(data2020092);
    this.loadData(data2020093);
    this.loadData(data2020094);
    this.loadData(data2020095);
    this.loadData(data2020101);
    this.loadData(data2020102);
    this.loadData(data2020103);
    this.loadData(data2020104);
    this.loadData(data2020111);
    this.loadData(data2020112);
    this.loadData(data2020113);
    this.loadData(data2020114);
    this.loadData(data2020121);
    this.loadData(data2020122);
    this.loadData(data2020123);
    this.loadData(data2020124);
    this.loadData(data2020125);
    this.loadData(data2021011);
    this.loadData(data2021012);
    this.loadData(data2021013);
    this.loadData(data2021014);
    this.loadData(data2021021);
    this.loadData(data2021022);
    this.loadData(data2021023);
    this.loadData(data2021024);
    this.loadData(data2021031);
    this.loadData(data2021033);

    // console.log('Loaded History Data:', this.historyData);
  }

  slugify(text) {
    return text.toString().toLowerCase()
      .replace(/\s+/g, '-')           // Replace spaces with -
      .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
      .replace(/\-\-+/g, '-')         // Replace multiple - with single -
      .replace(/^-+/, '')             // Trim - from start of text
      .replace(/-+$/, '');            // Trim - from end of text
  }

  loadData(data) {
    // Create a new "id" property which concatenates the symbol and date
    let stocks = data.map(i => {
      let date = new Date(i.Date);
      i.id = i.Ticker + '|' + date.toISOString();
      return i;
    });
    // Add to the historyData array
    stocks.forEach(i => {
      let obj = {};
      for (var prop in i) {
        if (i.hasOwnProperty(prop)) {
          obj[this.slugify(prop)] = i[prop];
          if (prop === "date") obj["date"] = new Date(i[prop]).toLocaleDateString();
        }
      }
      return this.historyData[i.id] = obj;
    });
    // Extract any new stock ticker symbols
    this.tickerSymbols = this.tickerSymbols.concat(data.map(i => i.Ticker)).unique();
    this.tickerSymbols.sort();
    // Extract the unique weeks from the data
    this.weeks = this.weeks.concat(data.map(i => i.Date)).unique();
  }

  getLatestTickerData(ticker) {
    let regex = new RegExp('^' + ticker + '\\\|', 'i');
    let tickerKeys = Object.keys(this.historyData)
      .filter(k => {
        let result = regex.test(k);
        return result;
      })
      .sort((a, b) => {
        let dateA = new Date(a.split('|')[keyIndexDate]);
        let dateB = new Date(b.split('|')[keyIndexDate]);
        if (dateA < dateB) return -1;
        if (dateB < dateA) return 1;
        return 0;
      })
      .reverse();
    return this.historyData[tickerKeys[0]];
  }

  getAllDataForTicker(ticker) {
    let data = [];
    // The keys will be pipe delimited so the regex needs to end in a pipe |.
    // Double escape the escape characters for the pipe.
    let regex = new RegExp('^' + ticker + '\\\|', 'i');
    let matchedKeys = Object.keys(this.historyData).filter(k => {
      let result = regex.test(k);
      return result;
    });
    matchedKeys.forEach(i => data.push(this.historyData[i]));
    return data;
  }

  getConsensusRatingData(ticker) {
    let allData = this.getAllDataForTicker(ticker);
    // console.log('AllData', allData);
    return allData.map(d => {
      return {
        label: new Date(d.date).toLocaleDateString(),
        rating: d['con-rating']
      };
    });
  }

}