// URL Resource Part 1: https://itnext.io/vuejs-and-webpack-4-from-scratch-part-1-94c9c28a534a
// URL Resource Part 2: https://itnext.io/vue-js-and-webpack-4-from-scratch-part-2-5038cc9deffb
// URL Resource Part 3: https://itnext.io/vue-js-and-webpack-4-from-scratch-part-3-3f68d2a3c127

<template>
  <div class="layoutRoot">
    <brig-title-bar />

    <aside>
      <ticker-list v-on:ticker-change="onTickerChange" />
    </aside>

    <div role="main">
      <ticker-header
        :ticker-symbol="selectedTicker"
        :ticker-history-service="tickerHistoryService"
        />
      <rating-chart
        :ticker-symbol="selectedTicker"
        :ticker-history-service="tickerHistoryService"
        />
      <primary-chart 
        size-ratio="3" 
        :ticker-symbol="selectedTicker"
        :line-tension="lineTension"
        />
    </div>
    
    <brig-footer-bar />
  </div>
</template>

<style lang="scss">
@import "./styles/theme.scss";

.layoutRoot {
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-rows: 4rem 1fr 3rem;
  grid-template-columns: auto 1fr;
  grid-template-areas: "header header" "sidebar body" "footer footer";
  grid-gap: 0;
  > header {
    grid-area: header;
    align-self: center; // background-color: #eee;
    > h1 {
      margin: 0;
    }
  }
  > aside {
    grid-area: sidebar;
  }
  > div[role="main"] {
    grid-area: body; // background-color: #ccc;
    display: grid;
    grid-template-rows: auto auto 1fr;
  }
  footer {
    padding: 1rem;
    grid-area: footer;
  }
}
</style>

<script>
import BrigTitleBar from "./components/BRIGTitleBar.vue";
import BrigFooterBar from "./components/BRIGFooterBar.vue";
import TickerHeader from "./components/TickerHeader.vue";
import TickerList from "./components/TickerList.vue";
import PrimaryChart from "./components/PrimaryChart.vue";
import RatingChart from "./components/RatingChart.vue";
import TickerHistoryService from "./services/TickerHistoryService";

const tickerHistoryService = new TickerHistoryService();

export default {
  components: {
    BrigTitleBar,
    BrigFooterBar,
    TickerHeader,
    TickerList,
    PrimaryChart,
    RatingChart
  },
  props: {},
  data: function() {
    return {
      tickerHistoryService: tickerHistoryService,
      selectedTicker: "",
      lineTension: 0.33
    };
  },
  methods: {
    onTickerChange: function(e) {
      if (e !== "(none)") {
        this.selectedTicker = e;
      } else {
        this.selectedTicker = null;
      }
    }
  }
};
</script>