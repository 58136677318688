var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "layoutRoot" },
    [
      _c("brig-title-bar"),
      _vm._v(" "),
      _c(
        "aside",
        [_c("ticker-list", { on: { "ticker-change": _vm.onTickerChange } })],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { role: "main" } },
        [
          _c("ticker-header", {
            attrs: {
              "ticker-symbol": _vm.selectedTicker,
              "ticker-history-service": _vm.tickerHistoryService
            }
          }),
          _vm._v(" "),
          _c("rating-chart", {
            attrs: {
              "ticker-symbol": _vm.selectedTicker,
              "ticker-history-service": _vm.tickerHistoryService
            }
          }),
          _vm._v(" "),
          _c("primary-chart", {
            attrs: {
              "size-ratio": "3",
              "ticker-symbol": _vm.selectedTicker,
              "line-tension": _vm.lineTension
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("brig-footer-bar")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }