var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("footer", [
      _c("p", [
        _c("small", [
          _vm._v(
            "\n        Created for the Book Reader's Investment Group (B.R.I.G.)\n      "
          )
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }