<template>
  <h2 :class="{ 'ticker--warning' : numAnalysts == 0 }">
    <div class="ticker__symbol">{{tickerSymbol}}</div>
    <img class="ticker__country-flag" :src="flagUrl" :alt="country" :title="country" />
    <div class="ticker__company">{{company}}</div>
    <div class="ticker__grades">
      <span title="Latest Profitability Grade">{{profitabilityGrade}}</span>
      <span title="Latest Financial Health Grade">{{financialHealthGrade}}</span>
      <span title="Latest Growth Grade">{{growthGrade}}</span>
    </div>
    <div class="ticker__exchange">{{exchange}}</div>
    <div class="ticker__numAnalysts" :class="{ 'ticker__numAnalysts--warning' : numAnalysts == 0 }">Analysts: {{numAnalysts}}</div>
  </h2>
</template>

<style lang="scss" scoped>
$headerBarPadding: 0.75rem;
h2 {
  display: grid;
  grid-gap: 0;
  grid-template-columns: auto auto 1fr auto auto auto;
  align-items: center;
  margin: 0;
  background-color: hsl(0, 0%, 95%);
  color: hsl(215, 89%, 52%);
}
.ticker__symbol,
.ticker__company,
.ticker__country-flag,
.ticker__exchange,
.ticker__grades,
.ticker__numAnalysts {
  display: block;
  padding: 0.5rem $headerBarPadding;
}
.ticker__symbol {
  font-weight: 300;
  color: hsl(215, 0%, 52%);
}
.ticker__company {
  padding: 0 $headerBarPadding 0 0;
}
.ticker__symbol,
.ticker__exchange,
.ticker__numAnalysts,
.ticker__grades {
  font-size: 1.125rem;
}
.ticker__exchange,
.ticker__numAnalysts {
  font-weight: 300;
}
.ticker__numAnalysts {
  &--warning {
    background-color: red;
  }
}
.ticker--warning {
  background-color: maroon;
  color: white;
}
.ticker__country-flag {
  height: 1.5rem;
  padding: 0 $headerBarPadding;
  font-size: 1rem;
  font-weight: 300;
  border-left: 1px solid hsl(0, 0%, 80%);
}
.ticker__grades,
.ticker__grades > span {
  cursor: default;
  font-family: 'Courier New', Courier, monospace;
}
</style>


<script>
import CountryService from "../services/CountryService";

const countryService = new CountryService();

export default {
  props: {
    tickerSymbol: { type: String },
    tickerHistoryService: { type: Object }
  },
  data: function() {
    return {
      company: "",
      country: "",
      flagUrl: "",
      numAnalysts: null,
      profitabilityGrade: "",
      financialHealthGrade: "",
      growthGrade: "",
      exchange: ""
    };
  },
  watch: {
    tickerSymbol: function() {
      console.log(
        "TickerHeader.vue",
        "Watched tickerSymbol changed to " + this.tickerSymbol
      );
      let tickerData = this.tickerHistoryService.getLatestTickerData(
        this.tickerSymbol
      );
      this.company = tickerData.company;
      this.country = tickerData.country;
      this.flagUrl = null;
      this.exchange = tickerData.exchange;
      this.numAnalysts = tickerData["number-of-analysts"];
      this.profitabilityGrade = tickerData['profitability-grade'];
      this.financialHealthGrade = tickerData['financial-health-grade'];
      this.growthGrade = tickerData['growth-grade'];
      // console.log("number of analysts:", this.numAnalysts, tickerData);

      countryService
        .getFlagImage(this.country)
        .then(flag => (this.flagUrl = flag));
    }
  }
};
</script>
