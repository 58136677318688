var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("header", { staticClass: "brig-title-bar" }, [
      _c("img", {
        attrs: {
          src: "google-prediction-api-icon.svg",
          alt: "B.R.I.G. Prediction History"
        }
      }),
      _vm._v(" "),
      _c("h1", [_vm._v("B.R.I.G. Prediction History")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }