<template>
  <div class="ticker-list">
    <input class="ticker-list__search" type="search" v-model="query" autofocus />
    <select @change="onChange" size="10" v-model="selectedSymbol">
      <!-- <option>(none)</option> -->
      <option v-for="symbol in symbols" :key="symbol">
        {{symbol}}
      </option>
    </select>
  </div>
</template>

<style lang="scss" scoped>
.ticker-list {
  height: 100%;
  display: grid;
  grid-template-rows: 3rem 1fr;
  overflow: hidden;
  select {
    width: 100%;
    height: 100%;
    padding: 0.5rem;
    border: 0 none;
    outline: none;
    color: hsl(0, 0%, 70%);
    option {
      padding: 0.125rem;
      font-size: 1.125rem;
      font-weight: 200;
    }
    &::selection {
      background-color: hsl(215, 89%, 52%);
    }
    &:focus {
      color: hsl(0, 0%, 33%);
    }
  }
  .ticker-list__search {
    width: 100%;
    margin: 0;
    padding: 0;
    min-width: 4rem;
    max-width: 8rem;
    border: 1px solid hsl(0, 0%, 50%);
  }
}
</style>

<script>
import TickerHistoryService from "../services/TickerHistoryService";

const tickerHistoryService = new TickerHistoryService();

export default {
  props: {},
  data: function() {
    return {
      symbols: tickerHistoryService.tickerSymbols,
      selectedSymbol: tickerHistoryService.tickerSymbols[0],
      query: ""
    };
  },
  watch: {
    query: function(e) {
      if (!e) {
        this.symbols = tickerHistoryService.tickerSymbols;
      } else {
        // If any items MATCHES the query perfectly, it should be the only one that shows, and it should $emit
        let perfectRegEx = new RegExp("^" + e + "$", "ig");
        let perfectList = tickerHistoryService.tickerSymbols.filter(i =>
          perfectRegEx.test(i)
        );
        if (perfectList.length == 1) {
          this.symbols = perfectList;
          this.$emit("ticker-change", this.symbols[0]);
        } else {
          // If the string STARTS with the query, it should show first
          let bestList = tickerHistoryService.tickerSymbols.filter(i => {
            let bestRegEx = new RegExp("^" + e, "ig");
            let response = bestRegEx.test(i);
            console.info(i, bestRegEx, response);
            return response;
          });
          // If the string CONTAINS the query, it should appear in the list
          let goodList = tickerHistoryService.tickerSymbols.filter(i => {
            let goodRegEx = new RegExp(e, "ig");
            return goodRegEx.test(i);
          });

          this.symbols = bestList.concat(goodList).unique();

          if (this.symbols.length == 1) {
            console.info(
              "Just show the one that matches.",
              this.symbols,
              bestList,
              goodList
            );
            this.$emit("ticker-change", this.symbols[0]);
          }
        }
      }
    }
  },
  mounted: function() {
    this.selectedSymbol = tickerHistoryService.tickerSymbols[0];
    this.$emit("ticker-change", this.selectedSymbol);
  },
  methods: {
    onChange: function(e) {
      this.selectedSymbol = e.target.value;
      this.$emit("ticker-change", this.selectedSymbol);
    }
  }
};
</script>
