var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "rating-chart" },
    [
      _c("chart-js-component", {
        ref: "chart",
        attrs: {
          "size-ratio": "8",
          "chart-type": "line",
          datasets: _vm.datasets,
          labels: _vm.labels,
          options: _vm.options
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }