Array.prototype.contains = function (v) {
  for (var i = 0; i < this.length; i++) {
    if (this[i] === v) return true;
  }
  return false;
};

Array.prototype.unique = function () {
  var arr = [];
  for (var i = 0; i < this.length; i++) {
    if (!arr.includes(this[i])) {
      arr.push(this[i]);
    }
  }
  return arr;
};

/**
 * Returns the first and the last items in the array.
 * Will fill the rest of the array with null.
 */
Array.prototype.firstAndLast = function () {
  var arr = [];
  for (var i = 0; i < this.length; i++) {
    if (i === 0 || i === this.length - 1) {
      arr.push(this[i]);
    } else {
      arr.push(undefined);
    }
  }
  return arr;
};

/**
 * Returns the first item for the first and last items in an array.
 * Will fill the rest of the array with null.
 */
Array.prototype.firstAsLast = function () {
  var arr = [];
  for (var i = 0; i < this.length; i++) {
    if (i === 0 || i === this.length - 1) {
      arr.push(this[0]);
    } else {
      arr.push(undefined);
    }
  }
  return arr;
};