<template>
  <canvas ref="canvas" :width="chartWidth" :height="chartHeight"></canvas>
</template>

<style lang="scss" scoped>
</style>

<script>
export default {
  props: {
    sizeRatio: { type: String },
    chartType: { type: String, default: "line" },
    options: {
      type: Object,
      default: function() {
        return {
          scales: {
            yAxes: [
              {
                gridLines: {
                  display: false
                },
                scaleLabel: {
                  display: true,
                  labelString: "$ Value"
                }
              }
            ]
          }
        };
      },
      required: false
    },
    labels: { type: Array, required: true },
    datasets: { type: Array, required: true }
  },
  computed: {
    chartWidth: function() {
      return this.chartHeight * this.sizeRatio;
    }
  },
  data: function() {
    return {
      chartHeight: 100,
      ctx: {}, // the chart context
      chart: {}, // the chart instance
      chartData: {
        type: this.chartType,
        data: {
          labels: this.labels,
          datasets: this.datasets
        },
        options: this.options
      }
    };
  },
  mounted: function() {
    this.ctx = this.$refs.canvas.getContext("2d");
    console.log("ChartJsComponent.vue", "Creating empty chart property");
    this.chart = new Chart(this.ctx, null);
  },
  methods: {
    loadChart: function(labels, datasets) {
      console.log("ChartJsComponent.vue", "Destroying previous chart");
      this.chart.destroy();

      this.chartData.data.labels = labels;
      this.chartData.data.datasets = datasets;

      console.log("ChartJsComponent.vue", "Creating chart with data", this.chartData);
      this.chart = new Chart(this.ctx, this.chartData);
    }
  }
};
</script>
